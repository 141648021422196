import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_filter_criteria = _resolveComponent("filter-criteria")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_risk_flag = _resolveComponent("risk-flag")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_advanced_filters = _resolveComponent("advanced-filters")!

  return (_openBlock(), _createBlock(_component_advanced_filters, {
    "filter-default": _ctx.filterDefault,
    "filter-dirty": _ctx.filterDirty,
    onReset: _ctx.reset,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.items')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            "model-value": _ctx.modelValue.item?.name,
            "display-name": "name",
            "search-by": "name",
            "search-fn": _ctx.findItemsByName,
            onOnSelect: _ctx.selectItem
          }, null, 8, ["model-value", "search-fn", "onOnSelect"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.locations')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_form_multi_select, {
            modelValue: _ctx.modelValue.locations,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.locations) = $event)),
            "search-by": "name",
            data: _ctx.locations
          }, null, 8, ["modelValue", "data"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.riskFlags')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.riskFlagRed,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.riskFlagRed) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.convertRiskFlagToDescription(_ctx.RiskFlagEnum.Red)) + " ", 1),
              _createVNode(_component_risk_flag, {
                "flag-color": _ctx.RiskFlagEnum.Red
              }, null, 8, ["flag-color"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.riskFlagOrange,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.riskFlagOrange) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.convertRiskFlagToDescription(_ctx.RiskFlagEnum.Orange)) + " ", 1),
              _createVNode(_component_risk_flag, {
                "flag-color": _ctx.RiskFlagEnum.Orange
              }, null, 8, ["flag-color"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.riskFlagBlack,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.riskFlagBlack) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.convertRiskFlagToDescription(_ctx.RiskFlagEnum.Black)) + " ", 1),
              _createVNode(_component_risk_flag, {
                "flag-color": _ctx.RiskFlagEnum.Black
              }, null, 8, ["flag-color"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.riskFlagNoFlag,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.riskFlagNoFlag) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.convertRiskFlagToDescription(_ctx.RiskFlagEnum.NoFlag)), 1)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.counts')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.modelValue.zeroQty,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelValue.zeroQty) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.includeZeroQtyRows')), 1)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["filter-default", "filter-dirty", "onReset", "onSubmit"]))
}