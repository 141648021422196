
import {
    computed, defineComponent, onBeforeMount, reactive, h,
} from 'vue';
import { ColumnDefinition, TableDefinition } from '@/types';
import InventoryService from '@/services/InventoryService';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import coreStore from '@/store/CoreStore';
import InventoryAdvancedFilters from '@/components/filters/InventoryAdvancedFilters.vue';
import { useFilterSearch } from '@/composable/useFilterSearch';
import InventorySearchFilter from '@/dtos/filters/InventorySearchFilter';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import Screen from '@/components/layout/Screen.vue';
import InventoryRiskIcons from '@/components/InventoryRiskIcons.vue';
import WarningIcon from '@/components/WarningIcon.vue';
import BRadioButtonGroup, { RadioButtonOption } from '@/components/bootstrap-library/BRadioButtonGroup.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import { } from '@/modules/master-data/views/inventory/screens/InventoryCategoryAdjustmentts.vue';
import router from '@/router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';

type State = {
    inventoryViewSelectedOption: string;
    loading: boolean;
};

export default defineComponent({
    name: 'master-data-inventory-list',
    components: {
        Thumbnail,
        InventoryRiskIcons,
        Screen,
        BAdvancedTable,
        BRadioButtonGroup,
    },
    setup() {
        const inventoryService = new InventoryService();
        const { userLocation } = coreStore.getInstance().profileStore;
        const adjustForSortViewValue = 'sortAdjustment';
        const { inventoryCategoryStore } = coreStore.getInstance();
        const inventoryViewOptions: RadioButtonOption[] = [
            { text: getTitleCaseTranslation('core.domain.systemInventory'), value: 'system' },
            { text: getTitleCaseTranslation('core.domain.adjustedForSorts'), value: adjustForSortViewValue },
        ];

        const finishedGoodInventoryCategory = inventoryCategoryStore.getFinishedGoodsCategory();

        const state = reactive<State>({
            inventoryViewSelectedOption: inventoryViewOptions[0].value,
            loading: true,
        });

        const {
            filterState, isFilterDefault, isFilterDirty, reset, state: filterSearchState, submit: filterSearch, recordCountWarning,
        } = useFilterSearch<InventoryGroupedDTO, InventorySearchFilter>({
            tableKey: 'masterDataInventoryList',
            defaultFilters: new InventorySearchFilter({
                locations: [userLocation],
            }),
            searchFunction: async (filter: InventorySearchFilter) => inventoryService.getFilteredInventoryGroupedByCategory(filter),
        });

        const categoryColumnDefinitions: Array<ColumnDefinition<any>> = inventoryCategoryStore.categories.map((x) => ({
            key: x.id, searchable: true, label: x.description,
        } as ColumnDefinition<any>));

        onBeforeMount(async () => {
            state.loading = false;

            // let this async method run without awaiting so the table can render and show progress spinner
            filterSearch();
        });

        function mapInventoryToTableData(data: InventoryGroupedDTO, viewValue: string): any {
            const typedData = new InventoryGroupedDTO(data);
            const mappedData = {
                locationName: typedData.locationName,
                locationId: typedData.locationId,
                itemId: typedData.itemId,
                itemName: typedData.itemName,
                quantityInbound: typedData.quantityInbound || 0,
                quantityOutbound: typedData.quantityOutbound || 0,
                riskFlag: typedData.riskFlag,
                riskScore: typedData.riskScore,
                imageUrlFull: typedData.imageUrlFull,
                imageUrlThumb: typedData.imageUrlThumb,
                customerItemNumber: typedData.customerItemNumber,
            };
            categoryColumnDefinitions.forEach((x) => {
                // typescript/eslint won't like this code. but its needed since the properties of `mappedData` are dynamic based on the categories in the db
                // @ts-ignore
                mappedData[x.key] = typedData.getInventoryForCategoryId(x.key as number, viewValue === adjustForSortViewValue);
            });
            return mappedData;
        }

        const table = computed(
            (): TableDefinition<any> => ({
                items: filterSearchState.resultSet.map((dto) => mapInventoryToTableData(dto, state.inventoryViewSelectedOption)),
                key: 'masterDataInventoryList',
                name: getTitleCaseTranslation('core.domain.inventory'),
                columnDefinition: [
                    {
                        key: 'imageUrlThumb',
                        label: getTitleCaseTranslation('core.domain.image'),
                    },
                    {
                        key: 'riskScore',
                        label: getTitleCaseTranslation('core.domain.risks'),
                        width: '75px',
                    },
                    {
                        key: 'locationName',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.location'),
                    },
                    {
                        key: 'customerItemNumber',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.containerNumber'),
                    },
                    {
                        key: 'itemName',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.containerName'),
                    },
                    ...categoryColumnDefinitions,
                    {
                        key: 'quantityInbound',
                        searchable: true,
                        label: getTranslation('core.domain.inboundInTransit'),
                    },
                    {
                        key: 'quantityOutbound',
                        searchable: true,
                        label: getTranslation('core.domain.outboundInTransit'),
                    },
                ],
                headIcon: () => h(WarningIcon, {
                    message: recordCountWarning.value,
                }),
                sidePane: () => h(InventoryAdvancedFilters, {
                    onSubmit: filterSearch,
                    onReset: reset,
                    modelValue: filterState.value,
                    disabled: state.loading,
                    filterDirty: isFilterDirty.value,
                    filterDefault: isFilterDefault.value,
                }),
            }),
        );

        async function getInventoryCategoryAdjustments(rowItem: any, categoryId: number) {
            state.loading = true;

            const inventoryCategory = categoryColumnDefinitions.find((category) => category.key === categoryId);
            const title = `${rowItem.locationName} | ${rowItem.itemName} | ${inventoryCategory?.label} (${rowItem[categoryId]})`;

            router.push({
                name: MasterDataRouteTypes.INVENTORY.ADJUSTMENT_LIST,
                params: {
                    locationId: rowItem.locationId,
                    itemId: rowItem.itemId,
                    inventoryCategoryId: categoryId,
                    adjustForSort: state.inventoryViewSelectedOption === adjustForSortViewValue ? 1 : 0,
                    title,
                },
            });

            state.loading = false;
        }

        function toCellName(category: number) {
            return `cell(${category})`;
        }

        return {
            filterSearchState,
            finishedGoodInventoryCategory,
            table,
            inventoryViewOptions,
            state,
            getInventoryCategoryAdjustments,
            categoryColumnDefinitions,
            toCellName,
        };
    },
});
