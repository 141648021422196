import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-116caa37"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_radio_button_group = _resolveComponent("b-radio-button-group")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_inventory_risk_icons = _resolveComponent("inventory-risk-icons")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      (!_ctx.state.loading)
        ? (_openBlock(), _createBlock(_component_b_advanced_table, {
            key: 0,
            "table-array": [_ctx.table],
            "sticky-header": "calc(100vh - 105px)",
            loading: _ctx.filterSearchState.loading,
            "dynamic-columns": "",
            "side-pane-options": {
                hideHeader: true,
            }
          }, _createSlots({
            toprow: _withCtx(() => [
              _createVNode(_component_b_radio_button_group, {
                modelValue: _ctx.state.inventoryViewSelectedOption,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.inventoryViewSelectedOption) = $event)),
                name: "inventory-view-switch",
                options: _ctx.inventoryViewOptions
              }, null, 8, ["modelValue", "options"])
            ]),
            "cell(imageUrlThumb)": _withCtx((row) => [
              _createVNode(_component_thumbnail, {
                "image-url-thumb": row.item.imageUrlThumb,
                "image-url-full": row.item.imageUrlFull
              }, null, 8, ["image-url-thumb", "image-url-full"])
            ]),
            "cell(riskScore)": _withCtx((row) => [
              _createVNode(_component_inventory_risk_icons, {
                "has-negative-finished-good-inventory": !!(_ctx.finishedGoodInventoryCategory && row.item[_ctx.finishedGoodInventoryCategory.id] && row.item[_ctx.finishedGoodInventoryCategory.id] < 0),
                risk: row.item.riskFlag
              }, null, 8, ["has-negative-finished-good-inventory", "risk"])
            ]),
            _: 2
          }, [
            _renderList(_ctx.categoryColumnDefinitions, (category) => {
              return {
                name: _ctx.toCellName(parseInt(category.key.toString())),
                fn: _withCtx((row) => [
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.getInventoryCategoryAdjustments(row.item, parseInt(category.key.toString())))
                  }, _toDisplayString(row.item[category.key.toString()]), 9, _hoisted_1)
                ])
              }
            })
          ]), 1032, ["table-array", "loading"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}