
import { defineComponent, PropType, toRefs } from 'vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import AdvancedFilters from '@/components/filters/AdvancedFilters.vue';
import FilterCriteria from '@/components/filters/FilterCriteria.vue';
import FormMultiSelect from '@/components/FormMultiSelect.vue';
import RiskFlag from '@/components/RiskFlag.vue';
import useItemSearch from '@/composable/useItemSearch';
import RiskFlagEnum from '@/domain/enums/RiskFlag';
import InventorySearchFilter from '@/dtos/filters/InventorySearchFilter';
import convertRiskFlagToDescription from '@/functions/enum';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'inventory-advanced-filters',
    components: {
        AdvancedFilters,
        BFormCheckbox,
        DropdownAutocompleteSingleSelect,
        FilterCriteria,
        FormMultiSelect,
        RiskFlag,
    },
    props: {
        filterDefault: Boolean,
        filterDirty: Boolean,
        modelValue: {
            type: Object as PropType<InventorySearchFilter>,
            default: () => new InventorySearchFilter(),
        },
    },
    emits: ['reset', 'submit'],
    setup(props, context) {
        const { locations } = masterDataStore.getInstance().locationStore;

        const { findItemsByName, selectItem } = useItemSearch(toRefs(props).modelValue);

        function reset() {
            context.emit('reset');
        }

        function submit() {
            context.emit('submit');
        }

        return {
            convertRiskFlagToDescription,
            findItemsByName,
            getTitleCaseTranslation,
            locations,
            reset,
            RiskFlagEnum,
            selectItem,
            submit,
        };
    },
});
